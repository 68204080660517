<template>
  <div id="user-equity">
    <div class="main-wrapper">
      <!-- 面包屑导航
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>账户中心</el-breadcrumb-item>
        <el-breadcrumb-item>
          <a>用户权益</a>
        </el-breadcrumb-item>
      </el-breadcrumb> -->
      <!-- 内容 -->
      <div class="user-equity-wrapper">
        <el-row>
          <!-- 侧边栏 -->
          <el-col :span="5" class="left-menu">
            <div class="wrapper-nav">
              <!-- <h4>账户中心</h4> -->
              <ul>
                <li :class="{ active: current == 1 }" @click="goeEqurity">
                  个人权益
                </li>
                <li :class="{ active: current == 2 }" @click="goMoneyBag">
                  我的钱包
                </li>
              </ul>
            </div>
          </el-col>
          <!-- 右侧内容 -->
          <el-col :span="19" style="margin-top: 10px">
            <!-- 个人权益 -->
            <div v-if="current == 1">
              <div style="float: left; margin-right: 20px">
                <!-- 免费盒子 -->
                <div class="equity freeBox" v-if="role == 1">
                  <el-row>
                    <el-col :span="7">
                      <div class="freeImg">
                        <img src="../../assets/imgs/free.png" alt="" />
                      </div>
                    </el-col>
                    <el-col :span="17">
                      <div>
                        <div class="free">{{ edition }}</div>
                      </div>
                    </el-col>
                    <div class="freeIcon">免费</div>
                  </el-row>
                </div>
                <!-- vip盒子 -->
                <div class="equity" v-if="role == 2 || role == 3 || role == 4">
                  <el-row>
                    <el-col :span="7">
                      <div class="vipImg">
                        <img src="../../assets/imgs/Vip.png" alt="" />
                      </div>
                    </el-col>
                    <el-col :span="17">
                      <div>
                        <div class="tall">{{ edition }}</div>
                        <div class="end">到期时间: {{ endTime }}</div>
                      </div>
                    </el-col>
                    <div class="vipIcon">VIP</div>
                  </el-row>
                </div>
              </div>
              <!-- 权益列表 -->
              <div v-if="equityListShow" style="float: left">
                <div
                  class="equity equityList"
                  v-for="(item, index) in roleList"
                  :key="index"
                >
                  <el-row>
                    <el-col :span="7">
                      <div class="vipImg">
                        <img src="../../assets/imgs/Vip.png" alt="" />
                      </div>
                    </el-col>
                    <el-col :span="17">
                      <div>
                        <div class="tall">
                          {{
                            item.id == 1
                              ? "体验版"
                              : item.id == 2
                              ? "标准版"
                              : item.id == 3
                              ? "高级版"
                              : "定制版"
                          }}
                        </div>
                        <div class="end" style="margin-bottom: 10px">
                          开始时间: {{ item.startDate }}
                        </div>
                        <div class="end" style="margin-bottom: 10px">
                          到期时间: {{ item.expireDate }}
                        </div>
                      </div>
                    </el-col>
                    <div class="vipIcon">VIP</div>
                  </el-row>
                </div>
              </div>
              <!-- 清除浮动 -->
              <div style="clear: both"></div>

              <!-- 个人权益 -->
              <div class="userEquity">
                <div class="alone">个人权益</div>
                <div>
                  <!-- 体验版 -->
                  <el-row v-if="role == 1">
                    <el-col :span="12">
                      <ul>
                        <li>支持分析文本总数量{{ singleTextCount }}条</li>
                        <li>支持单次分析最大文本数量{{ textConut }}条</li>
                        <li>支持分析结果的导出</li>
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>支持数据存储时间六个月</li>
                        <li>
                          支持单个分析应用项目最大数量{{ singleProjectCount }}个
                        </li>
                      </ul>
                    </el-col>
                  </el-row>
                  <!-- 标准版 -->
                  <el-row v-if="role == 2">
                    <el-col :span="12">
                      <ul>
                        <li>支持分析文本总数量{{ singleTextCount }}条</li>
                        <li>支持单次分析最大文本数量{{ textConut }}条</li>
                        <li>支持分析结果的导出</li>
                        <li>支持数据存储时间一年</li>
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>
                          支持单个分析应用项目最大数量{{ singleProjectCount }}个
                        </li>
                        <li>支持子文本库创建</li>
                        <li>支持文本库共享</li>
                        <li>支持高级分析算法</li>
                      </ul>
                    </el-col>
                  </el-row>
                  <!-- 高级版 -->
                  <el-row v-if="role == 3">
                    <el-col :span="12">
                      <ul>
                        <li>支持分析文本总数量{{ singleTextCount }}条</li>
                        <li>支持单次分析最大文本数量{{ textConut }}条</li>
                        <li>支持分析结果的导出</li>
                        <li>支持数据存储时间三年</li>
                        <!-- <li>
                          支持单个分析应用项目最大数量{{ singleProjectCount }}个
                        </li> -->
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>支持子文本库创建</li>
                        <li>支持文本库共享</li>
                        <li>支持高级分析算法</li>
                        <li>支持分布式集群</li>
                      </ul>
                    </el-col>
                  </el-row>
                  <!-- 定制版 -->
                  <el-row v-if="role == 4">
                    <el-col :span="12">
                      <ul>
                        <li>支持分析文本总数无限量</li>
                        <li>支持单次分析最大文本数无限量</li>
                        <li>支持分析结果的导出</li>
                        <li>支持数据永久存储</li>
                        <li>支持单个分析应用项目无限量</li>
                      </ul>
                    </el-col>
                    <el-col :span="12">
                      <ul>
                        <li>支持子文本库创建</li>
                        <li>支持文本库共享</li>
                        <li>支持高级分析算法</li>
                        <li>支持分布式集群</li>
                        <li>支持私有云独立部署</li>
                      </ul>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <!-- 我的钱包 -->
            <div v-if="current == 2">
              <div style="overflow: hidden">
                <!-- 账户余额盒子 -->
                <div class="accountBalance equity">
                  <el-row>
                    <el-col :span="10">
                      <div class="balance">
                        <img src="../../assets/imgs/money.png" alt="" />
                        <span>账户余额</span>
                      </div>
                    </el-col>
                    <el-col :span="14">
                      <div class="money">
                        <span class="icon">￥</span>
                        <span class="number">{{ accountBalance }}</span>
                        <div class="now" @click="recharge">立即充值</div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>

              <!-- 消费记录表 -->
              <!-- <div class="consume">
                <div class="alone">消费记录表</div>
                <div class="consumeList">
                  <el-table :data="consumeData" stripe style="width: 100%">
                    <el-table-column
                      prop="outTradeNo"
                      label="订单编号"
                      width="300%"
                    ></el-table-column>
                    <el-table-column
                      prop="createTime"
                      label="消费时间"
                    ></el-table-column>
                    <el-table-column
                      prop="amount"
                      label="消费金额"
                    ></el-table-column>
                    <el-table-column
                      prop="message"
                      label="消费信息"
                    ></el-table-column>
                  </el-table>
                </div>
                <div style="margin-top: 10px">
                  <el-pagination
                    @size-change="sizeChange"
                    @current-change="changePage"
                    @prev-click="prevClick"
                    @next-click="nextClick"
                    style="margin-top: 10px"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, sizes, jumper"
                    :total="totalCount"
                  ></el-pagination>
                </div>
              </div> -->
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// import $store from "/src/store/index";
import {
  useRoute,
  useRouter,
  onBeforeRouteUpdate,
  defineEmits
} from "vue-router";
import {
  ref,
  reactive,
  toRefs,
  toRaw,
  getCurrentInstance,
  onMounted,
  computed,
  watch
} from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService.js";

export default {
  emits: ["setBreadcrumbList"],
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();
    const instance = getCurrentInstance();
    // const emits = defineEmits(["setBreadcrumbList"]);

    context.emit("setBreadcrumbList", [
      // { title: "账户中心" },
      { title: "用户权益" }
    ]);

    // const cookies = instance.appContext.config.globalProperties.$VueCookies;
    // const httpPost = instance.appContext.config.globalProperties.$httpPost;
    let state = reactive({
      current: 1,
      edition: "", // 版本
      role: "", // 角色
      endTime: "",
      textConut: 0, // 分析文本总数量
      singleTextCount: 0, // 单次分析最大文本数量
      singleProjectCount: 0, // 单个分析应用项目最大数量
      accountBalance: 0, // 账户余额
      withdraw: 0, // 可提现余额
      ifRenew: false, // 是否续费
      consumeData: [
        {
          outTradeNo: "",
          createTime: "",
          amount: "",
          message: ""
        } // 消费记录表格
      ],
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      roleList: [], //用户暂未开始的权益列表
      equityListShow: false //是否显示权益列表
    });
    // function changeNav(val) {
    //   state.currentNav = val;
    // }
    // 获取角色个人权益
    function getRole() {
      httpPost("/auth/role").then((res) => {
        console.log("userEqui");
        if (res.code == 0) {
          state.role = res.data.id;
          if (res.data.role) {
            state.textConut = res.data.role.analysisNumberLimit;
            state.singleTextCount = res.data.role.recordNumberLimit;
          }
          // state.singleProjectCount = res.data.projectNum;
          state.endTime = res.data.expireTime;
          state.roleList = res.data.roleDTOList;
          // localStorage.setItem("analysableNum", res.data.analysableNum);
          if (state.roleList) {
            state.equityListShow = true;
          } else {
            state.equityListShow = false;
          }
          if (state.role == 1) {
            state.edition = "体验版";
          } else if (state.role == 2) {
            state.edition = "标准版";
          } else if (state.role == 3) {
            state.edition = "高级版";
          } else if (state.role == 4) {
            state.edition = "定制版";
          }
          // 当结束时间与当前时间相差七天时显示立即续费
          var now = new Date();
          var year = now.getFullYear(); //得到年份
          var month = now.getMonth(); //得到月份
          var date = now.getDate(); //得到日期
          var day = now.getDay(); //得到周几
          var hour = now.getHours(); //得到小时
          var minu = now.getMinutes(); //得到分钟
          var sec = now.getSeconds(); //得到秒
          month = month + 1;
          if (month < 10) month = "0" + month;
          if (date < 10) date = "0" + date;
          if (hour < 10) hour = "0" + hour;
          if (minu < 10) minu = "0" + minu;
          if (sec < 10) sec = "0" + sec;
          var time = "";
          time =
            year +
            "-" +
            month +
            "-" +
            date +
            "" +
            " " +
            hour +
            ":" +
            minu +
            ":" +
            sec +
            " ";
          var d1 = new Date(state.endTime);
          var d2 = new Date(time);
          let dValue = (d1 - d2) / 3600000 / 24;
          if (dValue <= 7) {
            state.ifRenew = true;
          } else {
            state.ifRenew = false;
          }
        } else {
          ElMessage({
            type: "error",
            message: res.message
          });
        }
      });
    }

    // 获取消费记录
    // function getConsume() {
    //   httpPost("/center/v1/buy/record", {
    //     pageSize: state.pageSize,
    //     pageNum: state.currentPage
    //   }).then((res) => {
    //     if (res.code == 0) {
    //       state.consumeData = res.data.data;
    //       state.totalCount = res.data.total;
    //       state.consumeData.forEach((item) => {
    //         if (item.type == 1) {
    //           item.type = "体验版";
    //         } else if (item.type == 2) {
    //           item.type = "标准版";
    //         } else if (item.type == 3) {
    //           item.type = "高级版";
    //         } else if (item.type == 4) {
    //           item.type = "定制版";
    //         }
    //         item.createTime = getDate(item.createTime);
    //       });
    //     }
    //   });
    // }

    // function getDate(date) {
    //   var dateee = new Date(date).toJSON();
    //   var date = new Date(+new Date(dateee) + 8 * 3600 * 1000)
    //     .toISOString()
    //     .replace(/T/g, " ")
    //     .replace(/\.[\d]{3}Z/, "");
    //   return date;
    // }

    // 前往个人权益
    function goeEqurity() {
      state.current = 1;
      context.emit("setBreadcrumbList", [
        // { title: "账户中心" },
        { title: "个人权益" }
      ]);
    }

    // 前往我的钱包
    function goMoneyBag() {
      context.emit("setBreadcrumbList", [
        // { title: "账户中心" },
        { title: "我的钱包" }
      ]);
      state.current = 2;
      httpPost("/user/balance").then((res) => {
        if (res.code == 0) {
          state.accountBalance = res.data.balance;
        } else {
          ElMessage({
            type: "error",
            message: res.message
          });
        }
      });
      // getConsume();
    }

    // 立即充值
    function recharge() {
      window.open("http://i.ringdata.com/balance");
    }

    // 立即续费
    function goRenew() {
      router.push("/pricing");
    }

    // 立即升级
    function goUp() {
      router.push("/pricing");
    }

    // 每页记录数改变时
    function sizeChange(size) {
      this.pageSize = size;
      // this.getConsume();
    }

    // 当前页改变时
    function changePage(currentPage) {
      state.currentPage = currentPage;
      // getConsume();
    }

    // 上一页
    function prevClick(currentPage) {
      state.currentPage = currentPage;
      // getConsume();
    }

    // 下一页
    function nextClick(currentPage) {
      state.currentPage = currentPage;
      // getConsume();
    }
    onMounted(() => {
      getRole();
    });
    return {
      ...toRefs(state),
      // changeNav
      getRole,
      // getConsume,
      // getDate,
      goeEqurity,
      goMoneyBag,
      recharge,
      goRenew,
      goUp,
      sizeChange,
      changePage,
      prevClick,
      nextClick
    };
  }
};
</script>

<style lang="scss" scoped>
#user-equity {
  .main-wrapper {
    .el-breadcrumb {
      margin-bottom: 40px;
    }
    .user-equity-wrapper {
      .left-menu {
        .avatar {
          text-align: center;
          img {
            border-radius: 50%;
            width: 150px;
            height: 150px;
          }
          p {
            text-align: center;
            font-size: 16px;
          }
        }
        .wrapper-nav {
          text-align: center;
          h4 {
            font-size: 15px;
            margin-bottom: 20px;
          }

          li {
            cursor: pointer;
            font-size: 14px;
            color: #111;
            display: block;
            text-decoration: none;
            padding: 5px 0px;
            line-height: 24px;
            &.active {
              color: #e94743;
            }
          }
        }
      }
      .freeBox {
        background-color: #eff7e3 !important;
      }
      .equity {
        margin-top: 10px;
        width: 290px;
        height: 100px;
        border: 1px solid #eee;
        border-radius: 10px;
        background-color: #fff4da;
        padding: 8px;
        position: relative;
        overflow: hidden;
        .vipImg {
          width: 55px;
          height: 55px;
          margin-bottom: 35px;
          padding-top: 10px;
          text-align: center;
          background-color: #ff847e;
          border-radius: 50%;
          img {
            width: 35px;
          }
        }
        .freeImg {
          width: 55px;
          height: 55px;
          margin-bottom: 35px;
          img {
            width: 55px;
          }
        }
        .free {
          font-size: 16px;
          color: #49bd8f;
          height: 16px;
          margin-bottom: 8px;
        }
        .tall {
          font-size: 16px;
          color: #e94743;
          height: 16px;
          margin-bottom: 8px;
        }
        .end {
          font-size: 11px;
          color: #999;
          margin-bottom: 20px;
        }
        //
        .now {
          font-size: 13px;
          color: #3385c2;
          cursor: pointer;
        }
        .vipIcon {
          width: 80px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          color: #fff4da;
          background-color: #f1bd50;
          position: absolute;
          top: 0px;
          right: -32px;
          transform: rotate(45deg);
          -ms-transform: rotate(45deg); /* IE 9 */
          -moz-transform: rotate(45deg); /* Firefox */
          -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
          -o-transform: rotaterotate(45deg); /* Opera */
        }
        .freeIcon {
          width: 80px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          color: #fff4da;
          background-color: #49bd8f;
          position: absolute;
          top: 0px;
          right: -32px;
          transform: rotate(45deg);
          -ms-transform: rotate(45deg); /* IE 9 */
          -moz-transform: rotate(45deg); /* Firefox */
          -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
          -o-transform: rotaterotate(45deg); /* Opera */
        }
      }
      .equityList {
        background-color: #fff;
        float: left;
        margin-right: 20px;
      }
      .userEquity {
        margin-top: 50px;
        .alone {
          color: #e94743;
          margin-bottom: 30px;
        }
        li {
          list-style: outside;
          color: #777;
          margin-bottom: 10px;
          margin-left: 18px;
        }
      }
      .accountBalance {
        float: left;
        padding: 10px;
        background-color: #f6f6f6 !important;
        border: 1px solid #e1e1e1 !important;
        .balance {
          width: 100%;
          span {
            color: #666;
          }
          img {
            width: 40px;
            vertical-align: middle;
          }
        }
        .money {
          text-align: right;
          .icon {
            color: #e94743;
            font-size: 14px;
          }
          .number {
            color: #e94743;
            font-size: 28px;
          }
          .now {
            margin-top: 25px;
          }
        }
      }
      .consume {
        margin-top: 50px;
        .alone {
          color: #e94743;
          margin-bottom: 30px;
        }
        .consumeList {
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
