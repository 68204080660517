<template>
  <!-- 登录页 -->
  <div class="login" id="login">
    <div class="login-main">
      <div class="form">
        <div class="login-title">
          <span>账号登录</span>
        </div>
        <el-form :model="loginForm" :rules="loginRules" ref="refLoginForm">
          <el-form-item prop="loginName">
            <el-input
              v-model="loginForm.loginName"
              placeholder="请输入邮箱/手机号"
              type="text"
            >
              <template #prefix>
                <el-icon>
                  <UserFilled />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              placeholder="请输入密码/验证码"
              type="password"
            >
              <template #prefix>
                <el-icon>
                  <Lock />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="loginForm.rememberMe">记住我</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
              :disabled="isDisabled"
              style="width: 100%"
              type="primary"
              @click="submitForm('refLoginForm')"
            >
              {{ btnName }}
            </el-button>
          </el-form-item>
          <p>
            <span>
              还没有账号？
              <span @click="toRegister" style="color: #e94743; cursor: pointer">
                立即注册！
              </span>
            </span>
            <span class="getPassword" @click="getPassword">忘记密码</span>
          </p>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { Debounce } from "@/utils/utils";
import crypto from "crypto";
import { ElMessage } from "element-plus";
import { httpPost, httpPostToken } from "@/api/httpService";
import VueCookies from "vue-cookies";
import { loadIframe } from "@/utils/loadIframe";
export default {
  name: "login",
  setup() {
    const router = useRouter();
    const instance = getCurrentInstance();

    /**数据 start */
    const refLoginForm = ref(null);
    let data = reactive({
      loginForm: {
        loginName: "",
        password: "",
        rememberMe: true
      },
      loginRules: {
        loginName: [
          {
            required: true,
            message: "请输入手机号/用户名/邮箱",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ]
      },
      isDisabled: false,
      btnName: "登录",
      btnLoading: false
    });
    /**数据 end */
    data.loginForm.loginName = window.localStorage.getItem("loginName");
    data.loginForm.password = window.localStorage.getItem("userPassword");
    function toRegister() {
      // router.push("/register");
      window.location.href =
        "https://www.ringdata.com/register?rp=https://wx.ringdata.com/login";
    }
    function getPassword() {
      // router.push("/findpassword");
      window.open("https://www.ringdata.com/findpass");
    }
    const submitForm = Debounce(function () {
      refLoginForm.value.validate((valid) => {
        if (valid) {
          var ip = window.localStorage.getItem("ip");
          var city = window.localStorage.getItem("city");
          var client = "STAT";
          data.isDisabled = true;
          data.btnLoading = true;
          data.btnName = "登录中";
          if (data.loginForm.password.toString().length != 32) {
            const md5 = crypto.createHash("md5");
            md5.update(data.loginForm.password);
            data.loginForm.password = md5.digest("hex");
          }
          httpPostToken(
            "/oauth/token",
            `grant_type=password&username=${data.loginForm.loginName}&password=${data.loginForm.password}&clientType=${client}&ip=${ip}&city=${city}`
          )
            .then((res) => {
              if (res) {
                //缓存用户名密码
                if (data.loginForm.rememberMe) {
                  window.localStorage.setItem(
                    "loginName",
                    data.loginForm.loginName
                  );
                  window.localStorage.setItem(
                    "userPassword",
                    data.loginForm.password
                  );
                } else {
                  window.localStorage.setItem("loginName", "");
                  window.localStorage.setItem("userPassword", "");
                }
                // 跨域免登录

                loadIframe("https://www.ringdata.com/freeLogin");

                let userInfo = VueCookies.get("userInfo");
                httpPost("/auth/role", null)
                  .then((res) => {
                    // console.log("login");
                    if (res && res.code === 0) {
                      // userInfo.nlpPermission = res.data;
                      // userInfo.ifLoginRS = true;
                      // this.setLoginStatus({
                      //   status: Date.now()
                      // })
                      // VueCookies.set("userInfo", userInfo);
                      router.replace({
                        path: "/home"
                      });
                      data.isDisabled = false;
                      data.btnLoading = false;
                      data.btnName = "登录";
                    } else {
                      router.push("/login");
                      data.isDisabled = false;
                      data.btnLoading = false;
                      data.btnName = "登录";
                    }
                  })
                  .catch((err) => {
                    data.isDisabled = false;
                    data.btnLoading = false;
                    data.btnName = "登录";
                  });
              } else if (res) {
                data.isDisabled = false;
                data.btnLoading = false;
                data.btnName = "登录";
                ElMessage.error(res.message);
              }
            })
            .catch((err) => {
              data.isDisabled = false;
              data.btnLoading = false;
              data.btnName = "登录";
            });
        } else {
          return false;
        }
      });
    });

    return {
      // data,
      refLoginForm,
      ...toRefs(data),
      toRegister,
      getPassword,
      submitForm
    };
  }
};
</script>
<style lang="scss" scoped>
#login {
  .login-main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../assets/imgs/login-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0;
    .form {
      width: 320px;
      height: 390px;
      background: #fff;
      padding: 30px 20px;
      border-radius: 5px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 120px;
      margin: auto;
      .login-title {
        font-size: 18px;
        font-weight: bold;
        color: #595959;
        border-bottom: 1px solid #d5d5d5;
        height: 40px;
        margin-bottom: 24px;
        span {
          display: inline-block;
          border-bottom: 3px solid $theme-color;
          height: 100%;
          padding: 0 5px;
        }
      }
      .getPassword {
        position: absolute;
        right: 20px;
        color: $theme-color;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  #login {
    .login-main {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: url(../assets/imgs/login-background.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center 0;
      .form {
        width: 300px;
        height: 390px;
        background: #fff;
        padding: 30px 20px;
        border-radius: 5px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        .login-title {
          font-size: 18px;
          font-weight: bold;
          color: #595959;
          border-bottom: 1px solid #d5d5d5;
          height: 40px;
          margin-bottom: 24px;
          span {
            display: inline-block;
            border-bottom: 3px solid $theme-color;
            height: 100%;
            padding: 0 5px;
          }
        }
        .getPassword {
          position: absolute;
          right: 20px;
          color: $theme-color;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
