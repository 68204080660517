// 存放校验函数
/**
 * 验证手机号格式
 */
const checkPhoneNum = function (phoneNum) {
  var myreg = /^1\d{10}$/;
  var tempStatus = false;
  if (phoneNum.length == 11 && myreg.test(phoneNum)) {
    tempStatus = true;
  }
  return tempStatus;
};
/**
 * randomWord 产生任意长度随机字母数字组合
 * randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
 * xuanfeng 2014-08-28
 */
const getRandomVerify = function (randomFlag, min, max) {
  var str = "",
    range = min,
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z"
    ];

  // 随机产生
  if (randomFlag) {
    range = Math.round(Math.random() * (max - min)) + min;
  }
  for (var i = 0; i < range; i++) {
    var pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
};
/**
 * 筛选条件
 */
const conditionToMap = function (field, op, type, value) {
  return {
    field: field,
    op: op,
    type: type,
    value: value
  };
};
const and = function () {
  let andMap = {
    logic: "$and",
    conditions: []
  };
  for (let value in arguments) {
    andMap["conditions"].push(arguments[value]);
  }
  return andMap;
};
const or = function () {
  let orMap = {
    logic: "$or",
    conditions: []
  };
  for (let value in arguments) {
    orMap["conditions"].push(arguments[value]);
  }
  return orMap;
};
const nor = function () {
  let norMap = {
    logic: "$nor",
    conditions: []
  };
  for (let value in arguments) {
    norMap["conditions"].push(value);
  }
  return norMap;
};
export { checkPhoneNum, getRandomVerify, conditionToMap, and, or, nor };
