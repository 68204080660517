<template>
  <div class="find-password" id="find-password">
    <div class="find-password-main">
      <el-row class="findPassword-wrapper">
        <el-col :span="24">
          <el-tabs tab-position="top">
            <el-tab-pane label="手机找回密码">
              <el-form
                :model="data.phoneForm"
                ref="refPhoneForm"
                :rules="data.phoneFormRules"
                label-width="85px"
                label-position="left"
              >
                <div v-if="data.step1">
                  <el-form-item label="手机号" prop="telephone">
                    <el-input
                      v-model="data.phoneForm.telephone"
                      type="text"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="code">
                    <el-col :span="15">
                      <el-input
                        v-model="data.phoneForm.code"
                        type="text"
                        maxlength="4"
                      ></el-input>
                    </el-col>
                    <el-col :span="9" class="img-wrapper">
                      <img
                        :src="data.picYZMSrc"
                        height="40"
                        @click="getVerify()"
                      />
                    </el-col>
                  </el-form-item>
                  <el-form-item label>
                    <el-button
                      style="width: 100%"
                      type="primary"
                      @click="next()"
                    >
                      下一步
                    </el-button>
                  </el-form-item>
                </div>
                <div v-if="data.step2">
                  <el-form-item style="color: red">
                    为了您的账户安全，请完成身份验证
                  </el-form-item>
                  <el-form-item label="手机号">
                    <el-input
                      v-model="data.phoneForm.telephone"
                      placeholder="请输入手机号"
                      disabled
                      type="text"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="验证码">
                    <el-row>
                      <el-col :span="13">
                        <el-input
                          v-model="data.phoneForm.dynamicPass"
                          placeholder="请输入验证码"
                          type="text"
                        ></el-input>
                      </el-col>
                      <el-col :span="11" style="text-align: right">
                        <el-button
                          type="primary"
                          :disabled="data.YZMString"
                          @click="sendCode"
                        >
                          {{ data.YZMString }}
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      style="width: 100%"
                      type="primary"
                      @click="nextAgain()"
                    >
                      下一步
                    </el-button>
                  </el-form-item>
                </div>
                <div v-if="data.step3">
                  <el-form-item style="color: red">
                    您正在找回的账号是：{{ data.userName }}
                  </el-form-item>
                  <el-form-item label="新密码">
                    <el-input
                      v-model="data.phoneForm.password"
                      type="password"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="确认新密码">
                    <el-input
                      v-model="data.phoneForm.password2"
                      type="password"
                      maxlength="20"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      style="width: 100%"
                      type="primary"
                      @click="confirm()"
                    >
                      确定
                    </el-button>
                  </el-form-item>
                </div>
                <div v-if="data.step4">
                  <div class="center-m">
                    恭喜，账号 {{ data.userName }} 重置密码成功！
                  </div>
                  <div class="center-m">
                    <el-button
                      style="width: 70%"
                      type="primary"
                      @click="goLogin()"
                    >
                      立即登录
                    </el-button>
                  </div>
                </div>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { checkPhoneNum, getRandomVerify } from "../utils/validation";
import { httpPost, baseAuthUrl } from "@/api/httpService";
import { ElMessage } from "element-plus";
const refPhoneForm = ref(null);
const instance = getCurrentInstance();
const router = useRouter();
/**数据 start */
var validatePhone = (rule, value, callback) => {
  value = value.trim();
  if (value === "") {
    callback(new Error("请输入手机号"));
  } else if (!checkPhoneNum(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    checkPhoneExit(value, () => {
      if (!data.phoneExit) {
        callback(new Error("此手机号未注册"));
      }
      callback();
    });
  }
};
var validatePic = (rule, value, callback) => {
  if (value.toUpperCase() != data.codePic.toUpperCase()) {
    callback(new Error("验证码错误"));
  }
  callback();
};
let data = reactive({
  phoneForm: {
    telephone: "",
    code: "",
    dynamicPass: "",
    password: "",
    password2: ""
  },
  phoneFormRules: {
    // 注册表单的验证规则
    telephone: [
      {
        required: true,
        validator: validatePhone,
        trigger: "blur"
      }
    ],
    code: [
      {
        required: true,
        validator: validatePic,
        trigger: "blur"
      }
    ]
  },
  YZMString: "获取验证码",
  countdown: 60, //倒计时
  YZMDisable: false, //发送验证码按钮是否禁用
  phoneExit: false, //电话是否存在
  picYZMSrc: null, //图形验证码地址
  codePic: null, //图形验证码
  step1: true,
  step2: false,
  step3: false,
  step4: false,
  isFindByemail: true,
  userName: ""
});
/**数据 end */

onMounted(() => {
  getVerify();
});
function getVerify() {
  //生成四位数字字母组合
  data.codePic = getRandomVerify(true, 4, 4);
  data.picYZMSrc = baseAuthUrl + "/code/v1/createKaptcha?code=" + data.codePic;
}
function next() {
  refPhoneForm.value.validate((valid) => {
    if (valid) {
      data.step1 = false;
      data.step2 = true;
    } else {
      return false;
    }
  });
}
function checkPhoneExit(phone, doSomething) {
  var param = {
    telephone: phone
  };
  httpPost("/reset/v1/checkPhoneExit", param).then((res) => {
    if (res && res.code === 0) {
      if (res.data) {
        data.phoneExit = true;
      } else {
        data.phoneExit = false;
      }
      doSomething();
    } else if (res) {
      ElMessage.error(res.message);
    }
  });
}
function sendCode() {
  //判断手机号码是否正确
  if (!checkPhoneNum(data.phoneForm.telephone)) {
    ElMessage.error("手机号码格式有误");
    return;
  }
  //倒计时60秒
  settime();
  //发送手机短信
  var param = {
    telephone: data.phoneForm.telephone
  };
  httpPost("/code/v1/phone/sendMessage", param).then((res) => {
    if (res && res.code === 0) {
      ElMessage({
        message: "发送成功",
        type: "success"
      });
    } else if (res) {
      ElMessage.error("发送失败");
      data.countdown = 0;
    }
  });
}
function settime() {
  //倒计时60秒
  if (data.countdown <= 0) {
    data.YZMDisable = false;
    data.YZMString = "重新发送";
    data.countdown = 60;
  } else {
    data.YZMDisable = true;
    data.YZMString = "重新发送(" + data.countdown + ")";
    data.countdown--;
    setTimeout(() => {
      settime();
    }, 1000);
  }
}
function nextAgain() {
  //判断验证码是否输入
  if (data.phoneForm.dynamicPass.trim() == "") {
    ElMessage.error("请输入验证码");
    return;
  }
  //判断验证码是否正确
  var param = {
    telephone: data.phoneForm.telephone,
    code: data.phoneForm.dynamicPass
  };
  httpPost("/reset/v1/phone/searchNameByPhone", param).then((res) => {
    if (res && res.code === 0) {
      data.userName = res.data;
      data.step1 = false;
      data.step2 = false;
      data.step3 = true;
    } else if (res) {
      ElMessage.error(res.message);
    }
  });
}
function confirm() {
  //判断密码长度
  if (data.phoneForm.password.trim() == "") {
    ElMessage.error("请输入密码");
    return;
  }
  if (data.phoneForm.password.trim().length < 8) {
    ElMessage.error("密码最短为八位");
    return;
  }
  if (data.phoneForm.password.trim() != data.phoneForm.password2.trim()) {
    ElMessage.error("两次输入密码不一致");
    return;
  }
  //修改密码
  var param = {
    telephone: data.phoneForm.telephone,
    password: data.phoneForm.password
  };
  httpPost("/reset/v1/phone/resetPWD", param).then((res) => {
    if (res && res.code === 0) {
      data.step1 = false;
      data.step2 = false;
      data.step3 = false;
      data.step4 = true;
    } else if (res) {
      ElMessage.error(res.message);
    }
  });
}
function goLogin() {
  router.push("/login");
}
</script>

<style lang="scss" scoped>
.center-m {
  margin: 20px 0px 30px 0px;
  text-align: center;
}
#find-password {
  .find-password-main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    .findPassword-wrapper {
      max-width: 600px;
      width: 70%;
      position: absolute;
      top: 20px;
      left: 0;
      bottom: 20px;
      right: 0;
      padding: 20px;
      margin: auto;
      .el-tabs {
        padding: 15% 15px 10px 15px;
      }
      img {
        cursor: pointer;
        margin-left: 10px;
        width: 220px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .el-button {
    padding: 12px 5px;
  }
  #find-password {
    .find-password-main {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      .findPassword-wrapper {
        max-width: 600px;
        width: 100%;
        position: absolute;
        top: 20px;
        left: 0;
        bottom: 20px;
        right: 0;
        padding: 20px;
        margin: auto;
        .el-tabs {
          padding: 0;
        }
        img {
          cursor: pointer;
          margin-top: 4px;
          margin-left: 10px;
          width: 220px;
        }
      }
    }
  }
}
</style>
