<template>
  <div class="register" id="register">
    <div class="register-main">
      <el-row class="form">
        <el-col :span="24">
          <!-- 注册板块 -->
          <el-tabs tab-position="top" class="main_content">
            <!--手机注册-->
            <el-tab-pane label="手机注册">
              <el-form
                ref="refRegisterForm"
                class="registerForm"
                :model="data.registerForm"
                :rules="data.registerRules"
              >
                <!-- 用户名 -->
                <el-form-item prop="name">
                  <el-input
                    type="text"
                    v-model="data.registerForm.name"
                    placeholder="请填写用户名"
                  />
                </el-form-item>
                <!-- 手机号 -->
                <el-form-item prop="phone">
                  <el-input
                    type="text"
                    v-model="data.registerForm.phone"
                    placeholder="请填写手机号码"
                  />
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password">
                  <el-input
                    type="password"
                    v-model="data.registerForm.password"
                    placeholder="密码（6-16个字符，区分大小写）"
                  />
                </el-form-item>
                <!-- 确认密码 -->
                <el-form-item prop="againPassword">
                  <el-input
                    type="password"
                    v-model="data.registerForm.againPassword"
                    placeholder="再次输入密码"
                  />
                </el-form-item>
                <!-- 验证码 -->
                <el-form-item prop="code">
                  <el-row>
                    <el-col :span="15">
                      <el-input
                        v-model="data.registerForm.code"
                        placeholder="请输入短信中6位数字校验码"
                        type="text"
                        maxlength="6"
                      />
                    </el-col>
                    <el-col :span="9" style="text-align: right">
                      <el-button
                        type="primary"
                        :disabled="data.YZMDisable"
                        @click="sendCode"
                      >
                        {{ data.YZMString }}
                      </el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <!-- 同意 -->
                <el-form-item>
                  <el-checkbox v-model="data.agreementCheck">
                    我已看过并同意
                    <a href>《用户注册协议》</a>
                  </el-checkbox>
                </el-form-item>
                <!-- 注册 -->
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="telSubmit('refRegisterForm')"
                  >
                    立即注册
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, reactive, getCurrentInstance } from "vue";
import { checkPhoneNum } from "../utils/validation";
import { httpPost,httpPostIsAnonymous } from "@/api/httpService";
import { ElMessage } from "element-plus";
const refRegisterForm = ref(null);
const router = useRouter();
/**数据 start */

var validatePass = (rule, value, callback) => {
  value = value.trim();
  if (value != "") {
    let reg =
      /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)^.{6,16}$/;
    if (!reg.test(value)) {
      callback(new Error("密码格式有误，必须为数字、字母、特殊符号中任意两种"));
    } else {
      callback();
    }
  } else {
    callback(new Error("密码不能为空，请输入密码"));
  }
};
var validateAgainPass = (rule, value, callback) => {
  value = value.trim();
  if (value != "") {
    if (data.registerForm.password != value) {
      callback(new Error("再次输入密码输入不正确，密码请保持一致！"));
    } else {
      callback();
    }
  } else {
    callback(new Error("再次输入密码不能为空！"));
  }
};
// 验证用户名
var validateName = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入用户名"));
  } else if (value.length < 2) {
    callback(new Error("用户名最短为2位"));
  } else if (value.length > 10) {
    callback(new Error("用户名最长为10位"));
  } else {
    callback();
  }
};
// 验证手机号
var validatePhone = (rule, value, callback) => {
  value = value.trim();
  if (value === "") {
    callback(new Error("请输入电话号码"));
  } else if (!checkPhoneNum(value)) {
    callback(new Error("电话号码格式有误"));
  } else {
    callback();
  }
};
let data = reactive({
  registerForm: {
    phone: "",
    name: "",
    code: "",
    password: "",
    againPassword: ""
  },
  registerRules: {
    phone: [
      {
        validator: validatePhone,
        trigger: "blur"
      }
    ],
    name: [
      {
        validator: validateName,
        trigger: "blur"
      }
    ],
    password: [
      {
        validator: validatePass,
        trigger: "blur"
      }
    ],
    againPassword: [
      {
        validator: validateAgainPass,
        trigger: "blur"
      }
    ],
    code: [
      {
        required: true,
        message: "请输入验证码",
        trigger: "blur"
      }
    ]
  },
  agreementCheck: true, //协议
  confirmDirty: false,
  YZMDisable: false,
  YZMString: "获取验证码", //获取验证码基础文本
  countdown: 60 //倒计时
});
/**数据 end */
function sendCode() {
  //判断手机号码是否正确
  if (!checkPhoneNum(data.registerForm.phone)) {
    ElMessage.error("手机号码格式有误");
    return;
  }
  //倒计时60秒
  settime();
  //发送手机短信
  var param = {
    telephone: data.registerForm.phone
  };
  httpPostIsAnonymous("/code/v1/phone/sendMessage", param).then((res) => {
    if (res && res.code === 0) {
      ElMessage({
        message: "发送成功",
        type: "success"
      });
    } else if (res) {
      ElMessage.error("发送失败");
      data.countdown = 0;
    }
  });
}
function settime() {
  //倒计时60秒
  if (data.countdown <= 0) {
    data.YZMDisable = false;
    data.YZMString = "重新发送";
    data.countdown = 60;
  } else {
    data.YZMDisable = true;
    data.YZMString = "重新发送(" + data.countdown + ")";
    data.countdown--;
    setTimeout(() => {
      settime();
    }, 1000);
  }
}
function telSubmit(formName) {
  refRegisterForm.value.validate((valid) => {
    if (valid) {
      // 判断是否同意用户协议
      if (!data.agreementCheck) {
        ElMessage({
          message: "请同意用户协议",
          type: "warning"
        });
        return false;
      }
      //手机注册
      data.registerForm.name = data.registerForm.name.trim();
      httpPostIsAnonymous("/register/v1/phone/register", data.registerForm).then((res) => {
        if (res && res.code === 0) {
          ElMessage({
            message: "注册成功",
            type: "success"
          });
          //跳往登录页面
          router.push("/login");
        } else if (res) {
          ElMessage.error(res.message);
        }
      });
    }
  });
}
</script>

<style lang="scss" scoped>
#register {
  .register-main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../assets/imgs/login-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0;
    .form {
      max-width: 600px;
      width: 70%;
      position: absolute;
      top: 20px;
      left: 0;
      bottom: 20px;
      right: 0;
      padding: 20px;
      background: #fff;
      margin: auto;
      box-sizing: border-box;
      // form 注册表单
      .main_content {
        height: 510px;
        padding: 15px 15px 10px 15px;
        border-radius: 5px;
        .ant-form {
          .ant-form-item-with-help {
            margin-bottom: 4.7px !important;
          }
          .ant-form-item {
            .ant-form-item-children {
              .ant-checkbox-wrapper {
                a {
                  color: #409eff;
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
              .anticon {
                position: absolute;
                top: 4px;
                left: 5px;
                z-index: 9;
                color: #c0c4cc;
                height: 100%;
                text-align: center;
                margin: 0 auto;
              }
              .icon_lock {
                position: absolute;
                top: 12px;
                left: 8px;
              }
            }

            .ant-input {
              height: 40px;
              padding: 0 30px;
            }
            .now_register {
              width: 100%;
            }
            .ant-btn {
              padding: 0 12px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .el-button {
    padding: 12px 5px;
  }
  #register {
    position: relative;
    .register-main {
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: url(../assets/imgs/login-background.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center 0;
      .form {
        max-width: 900px;
        width: 100%;
        position: relative;
        top: 0px;
        left: 0;
        bottom: 0px;
        right: 0;
        padding: 20px;
        background: #fff;
        margin: auto;
        box-sizing: border-box;
        // form 注册表单
        .main_content {
          height: 510px;
          padding: 15px 15px 10px 15px;
          border-radius: 5px;
          .ant-form {
            .ant-form-item-with-help {
              margin-bottom: 4.7px !important;
            }
            .ant-form-item {
              .ant-form-item-children {
                .ant-checkbox-wrapper {
                  a {
                    color: #409eff;
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
                .anticon {
                  position: absolute;
                  top: 4px;
                  left: 5px;
                  z-index: 9;
                  color: #c0c4cc;
                  height: 100%;
                  text-align: center;
                  margin: 0 auto;
                }
                .icon_lock {
                  position: absolute;
                  top: 12px;
                  left: 8px;
                }
              }

              .ant-input {
                height: 40px;
                padding: 0 30px;
              }
              .now_register {
                width: 100%;
              }
              .ant-btn {
                padding: 0 12px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
